import React from 'react';

import Layout from '../components/Layout';
import pic4 from '../assets/images/pic04.jpg';
import pic5 from '../assets/images/pic05.jpg';

const IndexPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>About</h2>
        </div>
      </header>

      <div className="wrapper">
        <div className="inner">
          <p>
            Maxine started learning the cello at the age of six and the piano at
            the age of five. Cello quickly became her main instrument and she
            won a County Scholarship at the age of ten and received an ABRSM
            Gold Award for the highest marks in the country for her Grade 8 at
            the age of seventeen. As a teenager Maxine learnt with Florence
            Hooton MBE and she went on to study at the Royal College of Music in
            London, learning with Michael Evans and Joan Dickson. At the RCM
            Maxine studied cello as her first instrument and piano as her
            second.
          </p>

          <p>
            During her freelance career, Maxine has played for the Queen and has
            also played alongside Mstislav Rostropovich, Jose Carreras, Hayley
            Westenra and ‘All Angels’. She has also toured with Castleward Opera
            in Northern Ireland, played with the Ulster Orchestra and in the
            West End musical “CATS” in Zurich. She now works as a freelance
            player and teacher in and around the Midlands where she manages, and
            plays in, her string quartet, “4tissimo”, whilst being a full-time
            mum to her two boys.
          </p>


          <section className="features">
            <article>
              <a href="/#" className="image">
                <img src={pic4} alt="" />
              </a>
              <h3 className="major">Sed feugiat lorem</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing vehicula id
                nulla dignissim dapibus ultrices.
              </p>
              <a href="/#" className="special">
                Learn more
              </a>
            </article>
            <article>
              <a href="/#" className="image">
                <img src={pic5} alt="" />
              </a>
              <h3 className="major">Nisl placerat</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing vehicula id
                nulla dignissim dapibus ultrices.
              </p>
              <a href="/#" className="special">
                Learn more
              </a>
            </article>
          </section>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
